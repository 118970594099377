<template>
    <div class="html-default">
        <label class="form-label" style="font-size: 10px;">{{DatetimeNow}}</label>
        <label class="form-label" style="float: right; font-size: 10px;">{{PurchasingOrderNumber}}</label>
        <br>
        <div class="row p-2">
            <div class="col-3 bg">
                <img class="item" :src="require('../../../public/img/avatars/Logo.jpg')" width="100%"/>
            </div>
            <div class="col-9 p-0">
                <br>
                <h2><b>PT. BOJONG WESTPLAS</b></h2>
                <label><b>Modern Pipe Manufacturer</b></label>
            </div>
        </div>

        <div style="text-align: center;">
            <h4><b>Order Pembelian</b></h4>
            <label>{{PurchasingOrderNumber}}</label>
        </div>

        <div class="row p-2">
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kantor</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyKantor }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Telepon</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPhoneNumber }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Fax</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyFax }}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Pabrik</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{ CompanyPabrik }} <br> {{CompanyProvinsi}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Tanggal</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{PurchasingOrderDate}}</label>
                    </div>
                </div>  
            </div>
            <div class="col-6 px-3">
                <div class="row">
                    <div class="col-sm-label">
                        <label class="form-label">Kepada</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label"><span>{{CustomerName}} <br> {{CustomerAddress}} <br> {{CustomerCity}} - {{CustomerProvince}} <br> {{CustomerCountry}}</span></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2">
            <label class="form-label">Dengan Hormat,</label><br>
            <label class="form-label">Kami memesan barang dengan kondisi sebagai berikut:</label>
            <table border="1" class="mytable mytable-head">
                <thead>
                    <tr>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Jumlah</label></td>
                        <td class="px-3" width="10%" style="text-align: center;"><label>Satuan</label></td>
                        <td class="px-3" width="40%" style="text-align: center;"><label>Nama Barang</label></td>
                        <td class="px-3" width="20%" style="text-align: center;"><label>Harga</label></td>
                        <!-- <td class="px-3" width="15%" style="text-align: center;"><label>Diskon</label></td> -->
                        <td class="px-3" width="20%" style="text-align: center;"><label>Total</label></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in Items" :key="index">
                        <td class="px-2"><label style="float: right;">{{item['quantity_string']}}</label></td>
                        <td class="px-2"><label>{{item['uom']}}</label></td>
                        <td class="px-2"><label>{{item['item_name']}}</label></td>
                        <td class="px-2"><label style="float: right;">{{CurrSymbol}} {{item['price_per_unit']}}</label></td>
                        <!-- <td class="px-2"><label style="float: right;">{{CurrSymbol}} {{item['discount']}}</label></td> -->
                        <td class="px-2"><label style="float: right;">{{CurrSymbol}} {{item['total_per_unit_string']}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-body">
                <tbody>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Total</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">{{CurrSymbol}} {{Total}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Discount {{Discount}}%</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">{{CurrSymbol}} {{TotalDiscount}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">PPN {{PPN}}%</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">{{CurrSymbol}} {{TotalPPN}}</label></td>
                    </tr>
                    <tr>
                        <td class="px-2" width="80%"><label style="float: right;">Grand Total</label></td>
                        <td class="px-2" width="20%"><label style="float: right;">{{CurrSymbol}} {{GrandTotal}}</label></td>
                    </tr>
                </tbody>
            </table>
            <table border="1" class="mytable mytable-footer">
                <tbody>
                    <tr>
                        <td class="px-2" width="100%"><label>#Terbilang: {{TotalString}} {{CurrName}}</label></td>
                    </tr>
                </tbody>
            </table>
            <br>
            <div class="row">
                    <div class="col-3">
                        <label class="form-label">Syarat Pembayaran</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{PaymentRule}} hari</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Note</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{Note}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">Tanggal Kirim</label>
                    </div>
                    <div class="col-sm-dot">
                        <label class="form-label">:</label>
                    </div>
                    <div class="col-sm-content">
                        <label class="form-label">{{DeliveryDate}}</label>
                    </div>
                </div>
                <br>
            
            <div class="row">
                <div class="col-8">
                </div>
                <div class="col-4">
                    <label class="form-label"><span>Tangerang, {{PurchasingOrderDate}}<br>Hormat Kami</span></label>
                </div>
            </div>
            <div class="row">
                <div class="col-1">
                    <label class="form-label">NPWP</label>
                </div>
                <div class="col-sm-dot">
                    <label class="form-label">:</label>
                </div>
                <div class="col-sm-content">
                    <label class="form-label">{{ CompanyNPWP }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-1">
                    <label class="form-label">Alamat</label>
                </div>
                <div class="col-sm-dot">
                    <label class="form-label">:</label>
                </div>
                <div class="col-sm-content">
                    <label class="form-label">{{ CompanyPabrik }} <br> {{CompanyProvinsi}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                </div>
                <div class="col-4">
                    <label class="form-label"><span>PT. Bojong Westplas</span></label>
                </div>
            </div>
            <br>
            <label class="form-label">Print by {{Name}} on {{DatetimeNow}}</label><br>
            <label class="form-label">PO ini tidak membutuhkan Tanda tangan karena sudah dibuat secara komputerisasi</label>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import requestOrderServices from '../../Areas/Purchasing/RequestOrder/Script/RequestOrderServices';
import currencyService from '../../Areas/MasterData/Currency/Script/CurrencyService.js';
import angkaTerbilang from '@develoka/angka-terbilang-js';

export default {
    name: 'RequestOrderHtml',
    data(){
        return{
            Id: this.$route.params.id,
            DatetimeNow: '',
            PurchasingOrderNumber: '',
            CustomerName: '',
            CustomerAddress: '',
            CustomerCity: '',
            CustomerProvince: '',
            CustomerCountry: '',
            PurchasingOrderDate: '',
            Items: [],
            PaymentRule: '',
            Note: '',
            DeliveryDate: '',
            Name: this.$cookies.get('Name'),
            Total: '',
            TotalPPN: '',
            TotalDiscount: '',
            GrandTotal: '',
            TotalString: '',
            PPN: '',
            Discount: '',
            CurrSymbol: '',
            CurrName: '',

            //Company Detail
            CompanyPhoneNumber : '',
            CompanyFax : '',
            CompanyKantor : '',
            CompanyNPWP : '',
            CompanyPabrik : '',
            CompanyProvinsi : '',
        }
    },
    async mounted () {
        //Company Detail
        var companyData = await this.$globalfunc.getCompanyInfo();
        this.CompanyPhoneNumber = companyData.Telepon;
        this.CompanyFax = companyData.Fax;
        this.CompanyKantor = companyData.Kantor;
        this.CompanyNPWP = companyData.NPWP;
        this.CompanyPabrik = companyData.Pabrik;
        this.CompanyProvinsi = companyData.Provinsi;

        this.DatetimeNow = moment(new Date()).format("YYYY-MM-DD hh:mm A");
        
        var data = await requestOrderServices.getPOQuery(this.Id);
        document.title = data.purchase_order_number;
        
        var customerData = data.supplier.metadata;
        
        this.PurchasingOrderNumber = data.purchase_order_number;
        this.PurchasingOrderDate = moment(data.purchase_order_date).format("DD MMMM YYYY");
        this.CustomerName = data.supplier_name;
        this.CustomerAddress = customerData.profile.address;
        this.CustomerCity = customerData.profile.city;
        this.CustomerCountry = customerData.profile.country == undefined ? "Indonesia" : customerData.profile.country;
        this.CustomerProvince = customerData.profile.province;
        
        //table
        this.Discount = 0;
        if(data.purchasing_purchase_order_detail != null){
            this.Discount = data.purchasing_purchase_order_detail[0].discount;
        }
        this.PPN = data.ppn_tax_percentage;
        var ppnTax = data.ppn_tax_percentage / 100;
        
        var itemData = requestOrderServices.itemPDF(data.purchasing_purchase_order_detail, ppnTax);
        this.Items = itemData;

        var total = requestOrderServices.getAllTotal(this.Items);
        this.Total = kendo.toString(total, "n2");

        var totalDiscount = total * (this.Discount / 100);
        this.TotalDiscount = kendo.toString(totalDiscount, "n2");

        var totalPPN = (total - totalDiscount) * ppnTax;
        this.TotalPPN = kendo.toString(Math.round(totalPPN), "n2");

        var grandTotal = total - totalDiscount + totalPPN;
        this.GrandTotal = kendo.toString(grandTotal, "n2");

        this.TotalString = this.$globalfunc.titleCase(angkaTerbilang(Math.round(grandTotal)));

        this.PaymentRule = data.terms_of_payment.toString();
        this.Note = data.notes;
        this.DeliveryDate = moment(data.delivery_order_date).format("DD MMMM YYYY");
        
        var currData = await currencyService.getCurrencyById(data.currency_id);
        this.CurrSymbol = currData.currency_symbol;
        this.CurrName = currData.currency_name
    },
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
.bg {
    background-color: transparent;
}
.item {
    mix-blend-mode: multiply;
}
</style>